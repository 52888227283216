<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card
          border-variant="primary"
          header="Datos personales"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row">
              <div class="col-md-6">
                <label class="typo__label">Datos del paciente</label>
                <b-input
                  disabled
                  :value="
                    person.name +
                      ' ' +
                      person.last_name +
                      ' ' +
                      person.last_name_2
                  "
                ></b-input>
              </div>
              <div class="col-md-6">
                <label class="typo__label">Seleccione especialidad</label>
                <multiselect
                  v-model="form.specialty"
                  deselect-label="No puedes remover este valor"
                  track-by="id"
                  label="name"
                  placeholder="Selecciona uno"
                  :options="specialties"
                  :searchable="true"
                  :allow-empty="false"
                  @select="selectSpecialty"
                >
                </multiselect>
              </div>
              <div class="col-md-6">
                <label class="typo__label"
                  >Seleccione profesional de la salud</label
                >
                <multiselect
                  v-model="form.doctor"
                  deselect-label="No puedes remover este valor"
                  track-by="id"
                  label="full_name"
                  placeholder="Selecciona uno"
                  :options="doctors"
                  :searchable="true"
                  :allow-empty="false"
                >
                </multiselect>
              </div>
              <div class="col-md-6">
                <label class="typo__label">Ingrese servicio</label>
                <multiselect
                  :close-on-select="false"
                  :clear-on-select="true"
                  v-model="form.analysis"
                  deselect-label="No puedes remover este valor"
                  track-by="id"
                  label="name"
                  placeholder="Selecciona los análisis"
                  :options="analyses"
                  :searchable="true"
                  :allow-empty="false"
                  :multiple="true"
                />
              </div>
            </div>
          </b-card-text>
          <b-card-footer>
            <div class="row">
              <div class="col-md-6">
                <h2>Precio total: S/ {{ total.toFixed(2) }}</h2>
              </div>
              <div class="col-md-6">
                <b-button variant="success" @click="saveLaboratoryAppointment"
                  >Guardar
                </b-button>
              </div>
            </div>
          </b-card-footer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      analyses: [],
      form: {
        analysis: [],
        specialty: null,
        doctor: null,
        custom_datetime: "2021-01-01T19:30",
        price: 18.0
      },
      specialties: [],
      doctors: [],
      person: {}
    };
  },
  computed: {
    total() {
      return this.customLodash.sumBy(this.form.analysis, "price") || 0.0;
    },
    analysesToSave() {
      return this.customLodash.map(this.form.analysis, "id");
    }
  },
  methods: {
    getPerson() {
      ApiService.get(`person/${this.$route.query.person}`).then(res => {
        this.person = res.data;
      });
    },
    saveLaboratoryAppointment() {
      let saveObj = {
        person: this.$route.query.person,
        analysis_details: this.form.analysis,
        doctor: this.form.doctor.id,
        specialty: this.form.specialty.id,
        price: this.total
      };
      ApiService.post("laboratory-appointment/", saveObj)
        .then(res => {
          this.axios.post("http://localhost:3030/laboratory/", res.data);
          this.axios.post("http://localhost:3030/laboratory/", res.data);
          this.axios.post("http://localhost:3030/laboratory/", res.data);
          this.makeToast("success");
          setTimeout(() => {
            this.$router.push({ name: "clinic_person_search" });
          }, 1000);
        })
        .catch(() => {
          this.makeToast("danger", "Ocurrió un error al guardar");
        });
    },
    getLaboratoryAnalyses() {
      ApiService.get("laboratory-analysis").then(res => {
        this.analyses = res.data;
      });
    },
    makeToast(variant = null, message = "Éxito al guardar") {
      this.$bvToast.toast(message, {
        title: `Todo bien`,
        variant: variant,
        solid: true
      });
    },
    getCurrentDateTimeFormat() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      let hour = String(today.getHours()).padStart(2, "0");
      let minutes = String(today.getMinutes()).padStart(2, "0");

      let formatted = yyyy + "-" + mm + "-" + dd + "T" + hour + ":" + minutes;
      console.log(formatted);
      this.form.custom_datetime = formatted;
    },
    getSpecialties() {
      ApiService.get("specialty", "?is_auxiliary=1").then(res => {
        this.specialties = res.data;
      });
    },

    selectSpecialty(selectedOption) {
      console.log(selectedOption);

      this.form.doctor = null;
      this.doctors = [];

      this.form.analysis = [];
      this.analyses = [];

      ApiService.get(
        "user-specialty/search/doctor",
        `?specialty=${selectedOption.id}`
      ).then(res => {
        this.doctors = res.data;
      });

      ApiService.get("service", `?specialty=${selectedOption.id}`).then(res => {
        this.analyses = res.data;
      });
    }
  },
  mounted() {
    this.getPerson();
    this.getCurrentDateTimeFormat();
    this.getLaboratoryAnalyses();
    this.getSpecialties();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laboratorio/Ecografías/Radiologías", route: "alert" },
      { title: "Agendar" }
    ]);
  }
};
</script>

<style scoped>
.card-header {
  padding: 1rem 2.25rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
